import React from "react";
import { Link } from "gatsby"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Logo from "../../images/logo.png";
import {useSiteMetadata} from "../../branding";

const LeftNavHeader = (props) => {
    const theme=useTheme();
    return <Box sx={{display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(0, 1),
              ...theme.mixins.toolbar,// necessary for content to be below app bar
              justifyContent: 'center',}}>
                <img src={Logo} height={theme.mixins.toolbar.minHeight} alt="optiontrader.tools" />
            </Box>;
}

const LeftNavFooter = (props) => {
    const siteMetadata = useSiteMetadata();
    const theme=useTheme();
    const drawerWidth=props.drawerWidth;
    return  <Box position="fixed" 
                sx={{top: 'auto', bottom: 0,
                    //derived from ...theme.mixins.toolbar, with addition of width property in @media queries:
                    minHeight:56,
                    "@media (min-width:0px)":{"minHeight":64, "width":`${drawerWidth}px`},
                    "@media (min-width:600px)":{ "minHeight":64, "width":`${drawerWidth}px`}
                    }}
            >
            <Divider/>
            <Box sx={{display:"flex",marginTop:"0px",marginBottom:"0px",...theme.mixins.toolbar,alignItems:"center",justifyContent:"center"}}>
            2024 © {siteMetadata.title}
            </Box>
    </Box>;
}


const LeftNav=(props)=> {
    const drawerWidth=props.drawerWidth;
    
    return <>
            <Box component="div" >
                <LeftNavHeader />
                <Divider />
                <List>
                    <ListItem key="/"> <Link to="/">Market Overview</Link></ListItem>
                    <ListItem><Link to="/tickerreport/">Ticker Report</Link></ListItem>
                    <ListItem><Link to="/maxpain/">Max Pain</Link></ListItem>
                    <ListItem><Link to="/optionpricer/">Option Pricer</Link></ListItem>
                    <ListItem><Link to="/spreadfinder/">Spread Finder</Link></ListItem>
                    <ListItem><Link to="/condorbuilder/">Condor Builder</Link></ListItem>
                    <ListItem><Link to="/pcratios/">Put/Call Ratios</Link></ListItem>
                </List>   
            </Box>
            <LeftNavFooter drawerWidth={drawerWidth}  />
        </>;
  };

export default LeftNav;