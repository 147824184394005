import React from "react"

const BreadCrumb = ({currPageTitle}) => (
    <div className="page-breadcrumb bg-white">
        {/* Bread crumb and right sidebar toggle */}
         <div className="row align-items-center">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h1 className="page-title text-uppercase font-medium font-14">{currPageTitle}</h1>
            </div>
           
        </div>
        {/* End Bread crumb and right sidebar toggle */}
    </div>
)

export default BreadCrumb