
import React from "react"
import { Link } from "gatsby"
import {useSiteMetadata,Mailto} from "../../branding";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { Toolbar } from "@mui/material";

const Footer = (props) => {
    const siteMetadata = useSiteMetadata();
    const drawerWidth = props.drawerWidth;
    const linkTable = (
    <table style={{ width:480, marginLeft:"auto",marginRight: "auto"}}>
        <tbody>
            <tr>
                <td style={{width:'25%', textAlign:'left',  paddingTop: "0px"}}><Link to="/disclaimer/">Disclaimer</Link></td>
                <td style={{width:'25%', textAlign:'center',paddingTop: "0px"}}><Link to="/tos/">Terms</Link></td>
                <td style={{width:'25%', textAlign:'center',paddingTop: "0px"}}><Link to="/privacy/">Privacy</Link></td>
                <td style={{width:'25%', textAlign:'right', paddingTop: "0px"}}><Link to="/contactus/">Contact </Link></td>
            </tr>
            <tr>
                <td colSpan="4" style={{textAlign:'center',paddingTop: "0px"}}>
                <Mailto email="tradingsolutions@harsip.com" subject={`${siteMetadata.title} feedback`} >
            tradingsolutions@harsip.com
          </Mailto>
                </td>
            </tr>
        </tbody>
        </table>
    );

return (
    <Box container="footer"  sx={{ display: 'flex' }}>
        <AppBar position="fixed" color="secondary" sx={{top: 'auto', bottom: 0, width: { sm: `calc(100% - ${drawerWidth}px)` }}}>
          <Toolbar >
            {linkTable}
          </Toolbar>
      </AppBar>
    </Box>
  );
    }

export default Footer;