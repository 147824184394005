import { createTheme } from '@mui/material/styles';

const baTheme = createTheme({
    palette: {
      primary: {
        main: '#C1D2EC',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#848484',
        contrastText: '#000000',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    overrides: {
      MuiButton: {
        root: {
          // Example: setting the background color for buttons
          backgroundColor: '#001f3f',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#000d1a',
          },
        },
      },
    },
  });


export default baTheme;