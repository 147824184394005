import { graphql,useStaticQuery } from "gatsby";
import React from "react";
export const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

/*
    some content will come from sitemetadata. 
      rather than pollute pages that need this content with GraphQL,
        provide this content from here
*/
export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            authorShort
            author
          }
        }
      }
    `)
  
    return data.site.siteMetadata
};

export default useSiteMetadata;
