import React from "react"
import Footer from "./footer"
import BreadCrumb from "./breadcrumb"
import Alert from '@mui/material/Alert';
import BidaskHelmet from "./head";
import TopNav from "./topnav.js";
import Box from '@mui/material/Box';
import RightPanel from "./rightpanel";
import theme from "./theme.js"
import {  ThemeProvider } from '@mui/material/styles';

const Ample = ({explainerBlurb, currPageTitle, seoDescription, location, children}) => {
   
        const explainer = (explainerBlurb) ? <Alert severity="info">{explainerBlurb}</Alert> : <></>
        const drawerWidth=180;

        return (
            <ThemeProvider theme={theme}>
            <BidaskHelmet pageTitle={currPageTitle} pageDescription={seoDescription} location={location} />
        
            <Box sx={{ display: 'flex' }}>
                <TopNav drawerWidth={drawerWidth} />
        
                <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} >
                    <br/><br/>
                    <BreadCrumb currPageTitle={currPageTitle}/>
                    
                    {explainer}
                    
                    <div className="container-fluid">
                        {/* Start Page Content */}
                        {children}
                        {/* End Page Content */}
                    </div>
                    <br/><br/><br/>
                    <RightPanel/>
                </Box>

                <Footer  drawerWidth={drawerWidth} />
            </Box>
            </ThemeProvider>
        );
  }

  export default Ample;

