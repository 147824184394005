import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../../branding";

function BidaskHelmet({ pageDescription, lang, meta, pageTitle, location }) {
    const siteMetadata = useSiteMetadata();

    //use the siteMetadata values as default if there is nothing passed in
    const metaDescription = pageDescription || siteMetadata.description;
    const title = pageTitle || siteMetadata.title;
    const siteUrl = siteMetadata.siteUrl;

  // og tag best practices: https://ahrefs.com/blog/open-graph-meta-tags/
  return (
    <Helmet
      htmlAttributes={{lang,}}
      title={title}
      link={[
        {
          rel: `canonical`,
          href: location
        }
      ]}
      
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: location
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${siteUrl}/og.jpg`
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  )
}

BidaskHelmet.defaultProps = {
  lang: `en`,
  meta: [],
  pageDescription: ``,
}

BidaskHelmet.propTypes = {
  pageDescription: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  pageTitle: PropTypes.string.isRequired,
}

export default BidaskHelmet;
